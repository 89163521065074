import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('form').on('click', '.remove-fields', function(event) {
      $(this).closest('.list-element').children('.remove-from-list').val('1');
      $(this).closest('.list-element').hide();
      return event.preventDefault();
    });

    $('form').on('click', '.remove-fields-temp-documents', function(event) {
      $(this).closest('.list-element').remove();
      return event.preventDefault();
    });

    $('form').on('click', '.add-fields', function(event) {
      var fieldset, position, regexp, time;
      time = new Date().getTime();
      fieldset = $(this).parents('fieldset');
      position = $(fieldset).find('.list-element').length;
      regexp = new RegExp($(this).data('id'), 'g');
      $(this).before($(this).data('fields').replace(regexp, position));
      return event.preventDefault();
    });
  }
}
