import { Controller } from "@hotwired/stimulus"

// const calculateTimeToBeReported = () => {
//
// }

export default class extends Controller {
  connect() {
    let calculateTime = this.calculateTime;
    $('body').on('change', "#working_day_from_hrs, #working_day_from_min, #working_day_to_hrs, #working_day_to_min, #working_day_additional_break_hrs", function(event) {
      if ($('#working_day_from_hrs').val().length != 0 && $('#working_day_from_min').val().length != 0 && $('#working_day_to_hrs').val().length != 0 && $('#working_day_to_min').val().length != 0) {
        let fromTime = $('#working_day_from_hrs').val() + ':' + $('#working_day_from_min').val() + ':00';
        let from = Date.parse('8 Nov 1983 ' + fromTime + ' GMT');

        let toTime = $('#working_day_to_hrs').val() + ':' + $('#working_day_to_min').val() + ':00';
        let to = Date.parse('8 Nov 1983 ' + toTime + ' GMT');

        let durationHr = parseFloat(((to - from) / 1000) / 3600);
        let durationToBeReported = null;

        let additionalBreakHr = $("#working_day_additional_break_hrs").val();
        additionalBreakHr = (additionalBreakHr.length != 0 ? additionalBreakHr : 0)
        additionalBreakHr = additionalBreakHr.replace(',', '.')

        if (!isNaN(durationHr)) {
          // document.getElementById("total-time").innerHTML = durationHr;
          let startBreakHrShort = parseFloat($("#start-break-hours").data('short'));
          let startBreakHrLong = parseFloat($("#start-break-hours").data('long'));

          if ((durationHr - additionalBreakHr) > startBreakHrShort && (durationHr - additionalBreakHr) <= startBreakHrLong ) {
            let minimumBreak = parseFloat($("#break-hours").data('short'));
            durationToBeReported = durationHr - minimumBreak - additionalBreakHr;
          } else if (durationHr > startBreakHrLong) {
            let minimumBreak = parseFloat($("#break-hours").data('long'));
            durationToBeReported = durationHr - minimumBreak - additionalBreakHr;
          } else {
            durationToBeReported = durationHr - additionalBreakHr;
          }

          if (!isNaN(durationToBeReported)) {
            document.getElementById("time-to-be-reported").innerHTML = durationToBeReported;
            calculateTime();
            $("#working-day-calculator").removeClass('hide');
          } else {
            $("#working-day-calculator").addClass('hide');
            document.getElementById("time-to-be-reported").innerHTML = '-';
          }
        } else {
          // document.getElementById("total-time").innerHTML = '-';
          document.getElementById("time-to-be-reported").innerHTML = '-';
        }
      } else {
        $("#working-day-calculator").addClass('hide');
      }
    });

    $('body').on('change', "#working_day_real_working_time_hrs, #working_day_travel_time_hrs, #working_day_organizational_time_hrs, #working_day_administrative_time_hrs, #working_day_meeting_time_hrs, #working_day_additional_break_hrs, #working_day_short_time_work_hrs", function(event) {
      calculateTime();
      $("#working-day-calculator").removeClass('hide');
    });

    $('body').on('focus', "#working_day_real_working_time_hrs, #working_day_travel_time_hrs, #working_day_organizational_time_hrs, #working_day_administrative_time_hrs, #working_day_meeting_time_hrs, #working_day_additional_break_hrs, #working_day_short_time_work_hrs", function(event) {
      let _this = this;
      setTimeout(function() {
        _this.select();
      } , 10);
    });

    calculateTime();
  }

  calculateTime() {
    let workingTime = null;
    let real_working_time = null;
    let travel_time = null;
    let organizational_time = null;
    let administrative_time = null;
    let meeting_time = null;
    let additional_breaks = null;
    let short_time = null;

    workingTime = $("#time-to-be-reported").text();

    if (workingTime.length != 0) {
      workingTime = parseFloat(workingTime);

      real_working_time = $("#working_day_real_working_time_hrs").val();
      real_working_time = parseFloat(real_working_time.replace(',', '.'));

      travel_time = $("#working_day_travel_time_hrs").val();
      travel_time = parseFloat(travel_time.replace(',', '.'));

      organizational_time = $("#working_day_organizational_time_hrs").val();
      organizational_time = parseFloat(organizational_time.replace(',', '.'));

      administrative_time = $("#working_day_administrative_time_hrs").val();
      administrative_time = parseFloat(administrative_time.replace(',', '.'));

      meeting_time = $("#working_day_meeting_time_hrs").val();
      meeting_time = parseFloat(meeting_time.replace(',', '.'));

      short_time = $("#working_day_short_time_work_hrs").val();
      short_time = parseFloat(short_time.replace(',', '.'));

      if (isNaN(real_working_time)) { real_working_time = 0.0 }
      if (isNaN(travel_time)) { travel_time = 0.0 }
      if (isNaN(organizational_time)) { organizational_time = 0.0 }
      if (isNaN(administrative_time)) { administrative_time = 0.0 }
      if (isNaN(meeting_time)) { meeting_time = 0.0 }
      if (isNaN(short_time)) { short_time = 0.0 }

      // additional breaks are already calculated in workingTime
      let currentHoursToBeReported = workingTime - real_working_time - travel_time - organizational_time - administrative_time - meeting_time - short_time;

      document.getElementById('time-calculator').innerHTML = currentHoursToBeReported;
      if (currentHoursToBeReported == 0.0) {
        $("#working-day-calculator").addClass('done');
      } else {
        $("#working-day-calculator").removeClass('done');
      }
    }
  }
}
